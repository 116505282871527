$primary-color: #1f4690;
$primary-color-alt: #211f60;
$secondary-color: white;
$acent-color: #e8aa42;
$acent-color-alt: #ffe5b4;
$complimentary-color: #231955;
$neutral-color: black;
$neutral-color-1: #1f292e;
$neutral-color-2: #415058;
$neutral-color-3: #c8cdd0;
$neutral-color-4: #f2f2f3;

$info-color: #5cb82e;
$success-color: #5cb82e;
$warning-color: #e8a530;
$error-color: #c91d1d;

$primary-font: 'Montserrat';
$secondary-font: 'Poppins';
