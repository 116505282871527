@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexRow {
  display: flex;
}

@mixin flexAlign {
  display: flex;
  align-items: center;
}

@mixin flexDirection {
  display: flex;
  flex-direction: column;
}

@mixin flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flexAround {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@mixin button {
  font-size: 0.9rem;
  border: none;
  height: 40px;
  cursor: pointer ;
  outline: none;
  border-radius: 5px;
  // background: none;
  font-weight: bold;
  font-family: $secondary-font;
  transition: 300ms ease-in-out;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
}

@mixin routes {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 2rem;

  li {
    cursor: pointer;
  }
}

@mixin logo {
  font-size: 1.5rem;
  font-weight: bold;
}

@mixin container {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
@mixin input {
  flex: 1;
  color: $neutral-color;
  padding: 0.5rem;
  border: none;
  height: 40px;
  outline: none;
  border-radius: 5px;
  background: none;
  font-family: $primary-font;
  font-weight: 400;
  font-size: 0.9rem;
}

@mixin textGradient {
  font-weight: bold;
  background-image: $text-gradient;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: 0.9rem;
}

button {
  font-size: 0.9rem;
}

.logo {
  img {
    display: inline;
    height: 60px;
    width: auto;
    margin: auto;
  }
}

//Media Queries
//
$site_content_width: 1024px;
$media-queries: (
  'mobile': (
    max-width: 667px,
  ),
  'tablet': (
    min-width: 668px,
  )
  and
  (
    max-width: $site_content_width,
  ),
  'desktop': (
    min-width: (
      $site_content_width + 1px,
    ),
  ),
);

@mixin respond($breakpoints...) {
  $conditions: ('only screen');
  @each $breakpoint in $breakpoints {
    // If the key exists in the map
    $conditions: append(
      $conditions,
      ' and ' #{inspect(map-get($media_queries, $breakpoint))}
    );
  }

  @media #{$conditions} {
    @content;
  }
}

//scrollbar customisation

//animations

@keyframes animatePing {
  0% {
    background: #fff;
    border: 0px solid #fff;
  }
  50% {
    background: #fff;
    border: 100px solid #222;
  }
  100% {
    background: #fff;
    border: 0px solid #222;
  }
}
