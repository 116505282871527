@import '../../../styles/constants';
@import '../../../styles/variables';

.hero__landing {
  min-height: 100vh; // Ensures the hero section takes full viewport height
  max-height: 100vh;
  width: 100% !important; // Forces full width, overriding other styles
  position: relative;

  .landing__container {
    position: absolute;
    top: 70px; // Positioned 70px from the top
    height: calc(100vh - 70px); // Height adjusted to fill remaining viewport height
    width: 100%;
    background: url('../../../assets/images/homeShape.png') no-repeat; // Background image
    background-size: cover;
    @include flexAlign; // Flexbox alignment mixin for centering content
    padding: 0 8rem; // Horizontal padding

    @include respond(mobile) {
      padding: 0 2rem; // Reduced padding on mobile for better responsiveness
    }

    .left__landing {
      flex: 1;
      height: 100%;
      position: relative;
      @include flexAlign; // Centering content within the left section

      .landing__content {
        width: 100%;
        @include flexDirection; // Flex direction mixin for layout control

        .landing__text {
          @include flexDirection; // Ensures flex properties are applied
          gap: 2rem; // Space between elements
          position: relative;

          h1 {
            font-size: 3rem; // Large text for primary heading
            font-weight: 700;
            position: absolute;
            width: 150%; // Width set to extend beyond normal bounds for visual effect
            z-index: 2;
            top: 2rem;
            color: $secondary-color;
            text-shadow: 2px 2px 5px $complimentary-color; // Text shadow for better readability
            transition: 300ms; // Smooth transition for any animation
            span {
              color: $acent-color; // Accent color for emphasis
            }

            @include respond(tablet) {
              font-size: 2.5rem; // Adjust font size for tablet
            }

            @include respond(mobile) {
              font-size: 2rem; // Adjust font size for mobile
              width: 100%; // Full width on mobile
            }
          }

          p {
            font-family: $secondary-font; // Secondary font for paragraph
            color: #ffffffb2; // Semi-transparent white color for text
            padding-top: 11rem; // Top padding to position text below the heading
            padding-bottom: 2rem;
          }
        }

        .landing__buttons {
          @include flexAlign; // Flex alignment for button container
          width: 100%;
          gap: 2rem; // Space between buttons

          a {
            @include button; // Button styling mixin
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $acent-color;
            font-family: $primary-font;
            padding: 0 2rem;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:first-child {
              background: $acent-color; // Background color for the first button
              color: $secondary-color;
            }
            &:last-child {
              border: 1px solid $acent-color; // Consistent border styling for the last button
              color: $secondary-color;
            }
          }
        }
      }
    }
    .right__landing {
      flex: 1;
      height: 100%;
      background-size: cover;
      @include flexCenter; // Centering content within the right section
      position: relative;
      @include respond(mobile) {
        display: none; // Hide on mobile
      }
      @include respond(tablet) {
        display: none; // Hide on tablet
      }
      .laptop {
        width: 120%; // Slightly larger width for visual emphasis
        display: inline;
        aspect-ratio: auto;
      }

      .laptop__design {
        display: inline;
        width: 85%; // Width relative to its container
        aspect-ratio: auto;
        position: absolute;
        right: 0.5rem; // Positioned slightly from the right
      }
    }
  }
}
