@import '../../styles/constants';
@import '../../styles/variables';
.navbar__home {
  background: $secondary-color;
  height: 90px;
  @include flexBetween;
  padding: 0 3rem;
  margin: 0;
  font-family: $primary-font;
  font-size: 0.9rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  transition: all 500ms ease-in-out;
  // box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4), inset 0 0 4px white;
  backdrop-filter: blur(10px);

  @include respond(mobile) {
    padding: 0 1rem;
  }
  @include respond(tablet) {
    padding: 0 1rem;
  }
  @include respond(desktop) {
    padding: 0 8rem;
  }
  &.nav-scrolled {
    top: -90px;
    @include respond(mobile) {
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4), inset 0 0 4px white;
    }
  }

  .nav-items {
    @include flexCenter;
    justify-content: flex-end;
    gap: 2rem;
    width: 80%;
    &.nav-mobile {
      position: fixed;
      transition: left 400ms ease-in-out;
      top: 90px;
      height: calc(100vh - 90px);
      width: 100%;
      color: $secondary-color;
      background-color: $primary-color;
      @include flexCenter;
      flex-direction: column;
      // align-items: center;
      // justify-content: space-around;
      flex-wrap: nowrap;
      padding-block: 4rem;
      .routes {
        flex-direction: column;
      }
    }

    &.show {
      left: 0;
    }
    &.hide {
      left: -100%;
    }
  }
  .logo {
    img {
      height: 60px;
      width: auto;
      margin: auto;
    }
  }

  .donate {
    button {
      @include button;
      background: $primary-color;
      border: 1px solid $secondary-color;
      padding: 0 1rem;
      font-weight: normal;
      // height: 30px;
      color: $acent-color;
    }
  }
  .routes {
    @include routes;
    .nav-link {
      transition: color, font-weight 300ms ease;
      &.active,
      &:hover {
        color: $acent-color;
        font-weight: 600;
      }
    }
  }

  .cta {
    button {
      @include button;
      background: $acent-color;
      padding: 0 1rem;
      font-weight: normal;
      // height: 30px;
      color: $primary-color;
    }
  }

  .mobile-toggle {
    display: none;
    button {
      @include button;
      @include flexCenter;
      aspect-ratio: 1/1;
      padding: 8px;
      border-radius: 50%;
      color: $acent-color;
      background-color: rgba($primary-color, 0.1);
      svg {
        font-size: 1.2rem;
      }
    }
    &:hover {
      button {
        background-color: $primary-color;
      }
    }
    &.show {
      display: inline-block;
    }
    &.hide {
      display: none;
    }
  }
}
