@import '../../styles/_constants.scss';
@import '../../styles/_variables.scss';

.alert-title {
  font-family: $primary-font;
  font-size: 1.8rem;
  font-weight: 600;
}

.alert-text,
.alert-message {
  font-family: $secondary-font;
  font-size: 1rem;
}

.alert-input {
  @include input;
  margin: 1rem auto;
  width: 80%;
  border: 1px solid $primary-color;
  &:active,
  &:focus,
  &:hover {
    box-shadow: 0 0 10px $neutral-color-3;
    border: 1px solid $primary-color;
  }
}

.confirm-button {
  @include button;
  padding: 4px 40px;
  margin-inline: 1rem;
  background-color: $primary-color;
  color: $secondary-color;
}

.cancel-button {
  @include button;
  padding: 4px 30px;
  margin-inline: 1rem;
  background-color: $warning-color;
  color: $secondary-color;
}

.deny-button {
  @include button;
  padding: 4px 30px;
  margin-inline: 1rem;
  background-color: $error-color;
  color: $secondary-color;
}
