@import './styles/_constants.scss';
@import './styles/_variables.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  padding: 0;
  margin: 0;
  font-size: 100%;
}
body {
  margin: 0;
  font-family: $secondary-font, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary-font;
}

p {
  font-family: $secondary-font;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
