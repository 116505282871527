@use '../../../styles/constants' as *;
@use '../../../styles/variables' as *;

.whyus-section {
  background: rgba($secondary-color, 0.6);
  padding: 4rem 8rem;
  @include respond(mobile) {
    padding: 4rem 1rem;
  }
  @include respond(tablet) {
    padding: 4rem 1rem;
  }
  .row {
    @include respond(tablet) {
      flex-direction: column;
    }
  }

  .reason {
    .icon {
      width: 100px !important;
      height: 100px !important;
      flex-shrink: 0;
      flex-basis: 100px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
    }
    width: min(100%, 500px);
    @include respond(mobile) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3,
      p {
        text-align: center;
      }
    }
  }
}
