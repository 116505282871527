@use '../../../styles/constants' as *;
@use '../../../styles/variables' as *;

.testimonials-section {
  padding: 4rem 8rem;
  @include respond(mobile) {
    padding: 4rem 1rem;
  }
  @include respond(tablet) {
    padding: 4rem 1rem;
  }

  .testimonials {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none !important;
    }
    .testimonial {
      flex: 0 0 100%;
      scroll-snap-align: start;
      position: relative;
      min-width: min(calc(100% - 2rem), 400px);
      height: auto;
      margin-inline: 1rem;
      margin-block: 1rem;
      border-radius: 5px;
      width: 100% !important;
      padding-top: 60px;
      @media (min-width: 1024px) {
        flex: 0 0 calc(50% - 2rem);
      }
    }
  }
}

.testimonial-image {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  z-index: 1;
}
