@import '../../styles/_constants.scss';
@import '../../styles/_variables.scss';


.banner{
    background-color: rgba($primary-color, 0.9);
    background-size: cover;
    // background-position: center/60%;
    box-shadow: inset 0 0 0 1000px rgba($primary-color, 0.9);
    //TODO Make this background a random choice among 4 bgs
    background: repeat-x url("../../assets/images/backgrounds/school-vector-seamless-pattern-blue-line-art-stationery-doodle-education-background-study_193606-578.avif");
    // &::before{
    //     content: "";
    //     position: absolute;
    //     z-index: -10;
    //     top: 0; 
    //     left: 0;
    //     bottom: 0;
    //     right: 0;
    //     // width: 100%;
    //     // height: 100%;
    //     background-size: cover;
    //     background-position: center/60%;
    //     background: repeat-x url("../../assets/images/backgrounds/blue-background-back-school_1053-591.avif");
    // }
}