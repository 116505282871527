@import '../../../styles/constants';
@import '../../../styles/variables';

$text-color: #333; // Define your desired text color here

.cta {
  button {
    @include button;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $acent-color;
    font-family: $primary-font;
    // font-size: 0.8rem;
    padding: 1.5rem 4rem;
    white-space: nowrap;
    text-overflow: ellipsis;

    // Add media query for phone screens
    @media (max-width: 600px) {
      // Adjust button styles for phone screens
      padding: 1rem 2rem;
      font-size: 0.7rem;
    }
  }

  // Add styles for images
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  // Add styles for text
  p, h1, h2, h3, h4, h5, h6 {
    margin: 0 0 1rem 0;
    line-height: 1.5;
    color: $text-color;
  }

  // Add styles for text in a card format
  .text-card {
    background-color: #fff; // Card background color
    padding: 1rem; // Padding inside the card
    border-radius: 8px; // Rounded corners
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    margin-bottom: 1rem; // Space between cards

    p, h1, h2, h3, h4, h5, h6 {
      margin: 0 0 0.5rem 0; // Adjust margin for card content
    }
  }
}