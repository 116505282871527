@import '../../../styles/constants';
@import '../../../styles/variables';

.banner {
  background-image: url(../../../assets/images/backgrounds/education-pattern-background-doodle-style_53876-115365.avif);
}

.member {
  position: relative;
  border: 1px solid rgba($primary-color, 0.7);
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .avatar {
      width: 100%;
      max-width: 150px;
      height: auto;
      border-radius: 50%;
      overflow: hidden;
      border: 8px solid rgba(0, 0, 0, 0.2);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }

    .info {
      margin-top: 10px;
      .depts {
        // Add styles for departments if needed
      }
      .contacts {
        width: 100%;
        padding: 10px 0;
        margin-block: 4px;
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
        border-radius: 5px;
      }
    }
  }
}

@include respond(tablet) {
  .member {
    flex-direction: row;
    text-align: left;
  }

  .container {
    flex-direction: row;
  }
}

@media (max-width: 600px) {
  .avatar {
    max-width: 120px;
  }
}
