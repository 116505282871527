@import '../../styles/_constants.scss';
@import '../../styles/_variables.scss';

.sidebar {
  position: fixed;
  top: 80px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 900;
  transition: all 0.3s;
  padding: 20px;
  padding-block: 40px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  overflow-y: scroll;
  padding-bottom: 1rem;
  @include respond(tablet) {
    left: -300px;
  }
  &.show {
    left: 0;
  }
  &.hide {
    left: -300px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 0;
      margin: 0;
      list-style: none;

      margin-bottom: 5px;
      a {
        display: flex;
        align-items: center;
        font-family: $primary-font;
        font-size: 15px;
        font-weight: 400;
        color: $primary-color;
        transition: 300ms ease;
        padding: 15px 30px;
        border-radius: 35px;
        svg {
          font-size: 16px;
          height: 20px;
          width: 24px;
          margin-right: 10px;
        }
        &:hover,
        &.active {
          background-color: $primary-color;
          color: $secondary-color;
        }
      }
    }
    .nav-heading {
      font-size: 11px;
      text-transform: uppercase;
      color: #899bbd;
      font-weight: 600;
      margin: 10px 0 5px 15px;
    }
  }
}
