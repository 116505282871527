@use '../../styles/constants' as *;
@use '../../styles/variables' as *;

.bread_crumb {
  width: 100%;
  padding: 0.5rem 2rem;

  box-shadow: 0px 0px 10px rgba(1, 41, 112, 0.1);
  border-radius: 8px;
  .crumb_title {
    font-family: $primary-font;
    font-weight: 700;
    font-size: 2rem;
    display: block;
    margin-bottom: .5rem;
  }
  .crumb_slug {
    color: rgba($neutral-color, 0.5);
    font-weight: 600;
    font-family: $secondary-font;
    a {
      transition: 300ms;
      &:hover,
      &.active {
        color: rgba($acent-color, 0.8);
      }
    }
    span {
      padding-inline: 4px;
      color: rgba($acent-color, 0.4);
    }
  }
}
