@import '../../styles/_constants.scss';
@import '../../styles/_variables.scss';

.footer__home {
  color: #fff;
  font-size: 1rem;
  background-color: $primary-color;
  // margin-top: 1.4rem;
  padding: 2rem 8rem;
  @include respond(mobile) {
    padding-inline: 1rem;
  }
  @include respond(tablet) {
    padding-inline: 1rem;
  }
  display: block;
  hr {
    height: 5px;
    outline: none;
    border: none;
    margin-inline: auto;
    margin-block: 1rem;
    width: 80%;
    // background-color: $acent-color-alt;
  }
  .footer-top {
    background-color: $primary-color;
    width: 100%;
    h4 {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      position: relative;
      padding-bottom: 12px;
    }
    .container {
      width: 100%;
      @include flexBetween;

      align-items: flex-start;
      flex-wrap: wrap;

      & > div {
        flex: 1;
        @include flexBetween;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        min-height: 100%;
        width: 100%;
        @include respond(mobile, tablet) {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 1rem;
        }
        @include respond(tablet) {
          // flex-direction: column;
          align-items: flex-start;
          margin-bottom: 1rem;
        }
      }
      & > div {
        flex: 1;
        & > div {
          padding: 1rem;
          min-width: 200px;
          min-height: 100% !important;
        }
        .footer-contact {
          .logo {
            img {
              background-blend-mode: unset;
              background-color: transparent;
            }
          }
          flex: 1;
          p {
            color: rgba($secondary-color, 0.8);
          }
        }
        .footer-links {
          flex: 1;
          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            svg {
              padding-right: 12px;
              color: $acent-color;
              font-size: 18px;
              line-height: 1;
            }
            li {
              padding: 10px 0;
              display: flex;
              align-items: center;
              &:first-child {
                padding-top: 0px;
              }
              a {
                color: rgba(255, 255, 255, 0.6);
                transition: 0.3s;
                display: inline-block;
                line-height: 1;
                &:hover {
                  text-decoration: none;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      div {
        .footer-newsletter {
          height: 100%;
          @include flexCenter;
          flex-direction: column;
          align-items: flex-start;
          flex: 1;
          color: rgba(255, 255, 255, 0.7);
          form {
            width: 100%;
            margin-top: 30px;
            background: #fff;
            padding: 12px 10px;
            position: relative;
            border-radius: 4px 0 0 4px;
            text-align: left;
            max-width: 390px;
            font-family: $secondary-font;
            input[type='email'] {
              outline: none;
              border: 0;
              color: $neutral-color;
              width: min(300px, calc(100% - 50px));
              font-family: $secondary-font;
              font-size: 0.9rem;
            }
            button {
              position: absolute;
              top: 0;
              right: -4px;
              bottom: 0;
              border: 0;
              background: none;
              padding: 0 20px;
              font: 16px $secondary-font;
              background: $acent-color;
              color: #fff;
              transition: 0.3s;
              border-radius: 0 4px 4px 0;
              box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    .container {
      @include flexBetween;
      @include respond(mobile) {
        flex-direction: column;
      }
      & > * {
        @include respond(mobile) {
          margin-bottom: 2rem;
        }
      }

      .credits {
        padding-top: 5px;
        font-size: 13px;
        color: #fff;
      }

      .social-links {
        position: relative;
        a {
          font-size: 18px;
          display: inline-block;
          background: rgba($secondary-color, 0.08);
          color: #fff;
          line-height: 1;
          padding: 8px 0;
          margin-right: 1rem;
          border-radius: 4px;
          text-align: center;
          width: 36px;
          height: 36px;
          transition: 0.3s;
          position: relative;
          &:hover {
            background: $acent-color;
            color: #fff;
            text-decoration: none;
          }
          &:hover::after {
            content: attr(title);
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            background-color: rgba(216, 205, 205, 0.7);
            color: #fff;
            padding: 5px;
            border-radius: 4px;
            white-space: nowrap;
            font-size: 12px;
            opacity: 1;
            visibility: visible;
            transition: opacity 0.3s;
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s;
          }
        }
      }
    }
  }

  .back-to-top {
    &.hide {
      display: none;
    }
    @include flexCenter;
    position: fixed;
    // visibility: hidden;
    // opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 15;
    background: $acent-color;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
    svg {
      font-size: 28px;
      color: #fff;
      line-height: 0;
    }
  }
}

.footer-newsletter h4 {
  font-size: 0.9rem;
}

.feedback-button {
  position: fixed;
  bottom: 80px; // Adjust as needed
  right: 15px; // Adjust as needed
  background: $acent-color;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: background 0.3s;
  z-index: 1000; // Ensure it is above other elements
  &:hover {
    background: darken($acent-color, 10%);
  }
}
