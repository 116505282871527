@import '../../../styles/_constants.scss';
@import '../../../styles/_variables.scss';

.login__home {
  @include flexCenter;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  .left {
    @include flexDirection;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    .form__container {
      background-color: $primary-color;
      width: clamp(320px, 60%, 600px);
      box-shadow: 20px 20px 12px 0px rgba(0, 0, 0, 0.25);
      text-align: center;
      // height: 100%;
      padding: 2rem 1rem;
      margin: 1rem;
      border-radius: 12px;
      position: relative;
      .back__button {
        @include flexCenter;
        position: absolute;
        color: $acent-color;
        top: calc(2rem + 10px);
        left: calc(2rem);
        padding: 10px;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        border: 1.5px dashed rgba($acent-color, 0.7);
        // background-color: rgba($neutral-color, 0.4);
        transition: background-color 200ms;
        cursor: pointer;
        &:hover {
          background-color: rgba($neutral-color, 0.4);
        }
      }
      .title__text {
        h2 {
          color: $acent-color;
          font-family: $primary-font;
          font-size: 28px;
          font-weight: 600;
          line-height: 46px;
          letter-spacing: 0em;
        }
      }
      form {
        @include flexDirection;
        .field {
          position: relative;
          margin-block: 6px;
          label {
            color: $secondary-color;
            width: 75%;
            display: inline-block;
            text-align: left;
          }

          .flag {
            display: block;
            position: absolute;
            left: 12%;
            top: 40%;
            height: 30px;
            width: 50px;
            padding: 4px;
            cursor: pointer;
            z-index: 5;
            background: no-repeat center/60%
              url('../../../assets/images/illustrations/1920px-Flag_of_Cameroon_(type_2).svg.png');
          }

          button[type='submit'] {
            @include button;
            width: 80%;
            background-color: $acent-color;
            font-family: $primary-font;
            font-weight: 800;
            border-radius: 5px;
            color: $secondary-color;
            cursor: pointer;
          }
          input {
            @include input;
            width: 80%;
            color: rgba($neutral-color, 0.8);
            font-family: $secondary-font;
            font-style: normal;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            &::placeholder {
              color: rgba($neutral-color, 0.4);
            }
            margin: 4px auto;
            background-color: $secondary-color;
            border-radius: 22px;
            border: 1px solid rgba($neutral-color, 0.4);

            &.login-password {
              display: inline-block;
              padding-right: 12%;
              padding-left: 5%;
            }
            &.login-password + .show-pass {
              position: absolute;
              right: 12%;
              bottom: 13px;
              transition: all 300ms;
              height: 15px;
              width: 15px;
              padding: 4px;
              border-radius: 50%;
              cursor: pointer;
              color: darken($acent-color, 0.4);
              background-color: rgba($neutral-color, 0.1);
              // background-color: green;
              &:hover {
                background-color: $primary-color;
                color: $secondary-color;
              }
            }
          }
        }
      }
    }
    .login__options {
      @include flexDirection;
      margin: 1rem;
      button {
        position: relative;
        width: 280px;
        height: 40px;
        background: $secondary-color;
        border: 1px solid rgb($neutral-color, 0.5);
        border-radius: 8px;
        margin: 5px;
        margin-block: 10px;
        cursor: pointer !important;
        background-size: 100% 200%;
        background-image: linear-gradient(
          to bottom,
          $secondary-color 50%,
          $primary-color 50%
        );
        -webkit-transition: background-position 1s;
        -moz-transition: background-position 1s;
        transition: background-position 200ms;
        // transition: color 1400ms;

        &:hover {
          background-position: 0 -100%;
          color: $secondary-color;
        }
        img {
          position: absolute;
          left: 7px;
          bottom: calc(50% - 30px / 2);
          height: 30px;
        }
      }
    }

    .to_login {
      width: max(100%, 280px);
      span {
        display: inline-block;
        text-align: center;
        width: 100%;
        transition: 300ms ease;
        a {
          color: $acent-color;
          &:hover {
            color: rgba($acent-color, 0.7);
            border-bottom: 1px solid rgba($acent-color, 0.7);
          }
        }
      }
    }
  }
  .right {
    color: rgba($secondary-color, 0.7);
    height: 100%;
    width: 50%;
    overflow-y: hidden;
    @include flexCenter;
    flex-direction: column;
    background-color: $primary-color;
    -webkit-clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);

    @include respond(tablet) {
      display: none;
    }

    .illustration {
      height: 60%;
      width: 100%;
      padding: 0;
      margin: 0;
      @include flexCenter;
      img {
        height: 100%;
        width: auto;
        margin: auto;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-name: pulse;
        animation-iteration-count: infinite;
        animation-delay: 1s;
      }
    }
  }
}
