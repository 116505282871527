@use '../../../styles/constants' as *;
@use '../../../styles/variables' as *;

.partners-section {
  padding: 4rem 8rem;
}

.partners{
  .partner{
    transition: 300ms ease-in-out;
    &:hover{
      transform: scale(1.1);
    }
  }
}