.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between images */
  padding: 10px;
}

.gallery-image {
  width: 100%;
  max-width: 320px; /* Maximum width for each image */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the entire space */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for hover effect */
}

.gallery-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
  filter: brightness(1.1); /* Brighten image on hover */
}

@media (max-width: 1024px) {
  .gallery-image {
    max-width: 250px; /* Adjust max width for medium screens */
  }
}

@media (max-width: 768px) {
  .gallery-image {
    max-width: 200px; /* Adjust max width for smaller screens */
  }
}

@media (max-width: 480px) {
  .gallery {
    flex-direction: column; /* Stack images vertically on very small screens */
  }
  .gallery-image {
    max-width: 100%; /* Full width on very small screens */
  }
}

html, body {
  height: 100%; // Ensure the body takes the full height of the viewport
  margin: 0; // Remove default margin
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensure the root element takes at least the full height of the viewport
}

main {
  flex: 1; // Allow the main content to grow and fill the space
}

footer {
  background-color: #f8f9fa; // Example background color
  padding: 10px;
  text-align: center;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--caption-background);
  color: var(--text-color);
  padding: 5px;
  text-align: center;
  font-size: 14px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0; // Start hidden
  transform: translateY(10px); // Start slightly below
}

.image-container:hover .image-caption {
  opacity: 1; // Show on hover
  transform: translateY(0); // Slide up into view
}

.info-button {
  background-color: var(--button-background);
  color: var(--button-text-color);
  // ... existing styles ...
}
