@import '../../styles/_constants.scss';
@import '../../styles/_variables.scss';

.dashboard__nav {
  transition: all 0.5s;
  z-index: 997;
  height: 80px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: $secondary-color;
  width: 100% !important;
  position: relative;
  padding-inline: 1rem;
  @include flexBetween;
  .logo__container {
    @include flexBetween;
    padding-left: 4px;
    a {
      img {
        height: 50px;
      }
      margin-right: 10px;
    }
    .toggle {
      @include flexCenter;
      svg {
        height: 20px;
      }
      height: 35px;
      width: 35px;
      padding: 4px;
      cursor: pointer;
      color: #012970;
      border-radius: 50%;
      background-color: rgba($acent-color, 0.5);
      transition: 300ms ease;
      &:hover {
        box-shadow: 0 0 0 2px rgba($primary-color, 0.5),
          0 0 0 3px $primary-color;
      }
    }
  }

  .searchbar {
    min-width: 360px;
    padding: 0 20px;
    @include respond(tablet) {
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      padding: 20px;
      box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
      background: white;
      z-index: 9999;
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
    }
    form {
      width: 100%;
      position: relative;
      input {
        text-align: center;
        font-family: $secondary-font;
        font-size: 16px;
        border: 1px solid $primary-color;
        padding: 7px 38px 7px 8px;
        border-radius: 22px;
        // transition: 0.3s;
        width: 100%;
        height: 40px;
        &:hover,
        &:focus {
          outline: none;
          
          box-shadow: 0 0 10px $neutral-color-3;

        }
      }
      button {
        position: absolute;
        border: 0;
        padding: 0;
        right: 20px;
        top: calc(20px - 20px / 2.1);
        // margin-top: -2px;
        background: none;
        cursor: pointer;
        svg {
          color: $primary-color;
          height: 20px;
        }
      }
    }
  }

  .navigation {
    border-left: 1px solid rgba($color: $primary-color, $alpha: 0.5);
    padding-inline: 10px;
    @include flexCenter;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      @include flexAround;

      li {
        // margin: 5px;
        margin-inline: 10px;
        a {
          svg {
            height: 28px;
            color: $primary-color;
          }
          @include flexCenter;
          height: 40px;
          position: relative;
          &:hover {
            .badge {
              animation: wobble 1.5s infinite linear;
            }
          }
          .badge {
            position: absolute;
            // inset: -2px -5px auto auto;
            top: 4px;
            right: -1px;
            @include flexCenter;
            font-weight: normal;
            font-size: 12px;
            // padding: 3px 6px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $acent-color;
          }
        }
      }

      // .notification {
      // }

      // .message {
      // }
      .profile {
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
        text-justify: center;
        @include flexAround;
        
        img {
          border-radius: 100%;
          padding: 1px;
          border: 1.5px solid $primary-color;
          height: 40px;
          width: 40px;
          size: cover;
          // aspect-ratio: 1/1;
          @include respond(mobile) {
            margin-right: 5px;
          }
        }
        span {
          font-family: $primary-font;
          // font-size: 12px;
          font-weight: 500;
          padding-inline: 5px;
          @include respond(mobile) {
            display: none;
          }
        }
        svg {
          height: 18px;
          color: $primary-color;
        }

        button{
          @include button;
          background-color: $primary-color;
          transition: 300ms ease-out;
          padding: 10px;
          color: $secondary-color;
          font-weight: 400;
          font-size: 0.5rem;
          svg{
            height: 16;
            color: $acent-color;
          }

          &:hover {
            transform: translateY(-3px);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}
