@import '../../../styles/_constants.scss';
@import '../../../styles/_variables.scss';

.error-main {
  min-height: calc(100vh - 100px); //calc(100vh - 10%);
  margin-top: 90px;
  padding: 5rem 2rem;

  &::before{
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: no-repeat center/60%
      // url('../../../assets/images/illustrations/404/404-8211-Not-Found-V1.png');
      url('../../../assets/images/illustrations/404/404\ Page\ Not\ Found\ _Monochromatic.svg');
    filter: grayscale(100%);
  }

  .error-content{
    position: relative;

  .to-home {
    @include button;
    background-color: $primary-color;
    padding: 22px 45px;
    cursor: pointer;

    .arrow-back{
      animation-duration: 8s;
      animation-fill-mode: both;
      animation-name: shakeX;
      animation-iteration-count: infinite;
      animation-delay: 50ms;
    }
  
  }
}
  
}
